function bannerViewed(banner) {
  const viewed = Boolean(localStorage.getItem(banner));
  return viewed;
}

function setBannerViewed(banner) {
  localStorage.setItem(banner, true);
}

export { bannerViewed, setBannerViewed };
